.bannerbox__outta {
  margin: 78px 0;
  padding: 0px;
  height: 600px;
  width: 100%;
  background-image: url(../Banner/BannerAsset/food1.jpeg);
  background-position: left center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.bannerboxInner_opacity {
  width: inherit;
  height: inherit;
  background: rgb(232, 232, 232, 0.6);
}
.banner__content1 {
  padding: 10% 20% 10% 20%;
  text-align: center;
}
.banner__content1 h1 {
  font-size: 700%;
  font-weight: 800;
  letter-spacing: 1.4px;
  word-spacing: 2px;
  width: 100%;
}
.banner__btn {
  background-color: #f36127;
  cursor: pointer;
  width: 250px;
  height: 70px;
  margin-top: 50px;
  border-radius: 15px;
  text-transform: uppercase;
  text-align: center;
  box-shadow: -1px 12px 14px 0px rgba(0, 0, 0, 0.7);
}

.banner__btn p {
  font-size: 25px;
  font-weight: bolder;
  margin-top: 20px;
  color: white;
}
.banner__btn p:active, .banner__btn p:hover{
  color: black;
}

@media all and (max-width: 375px){
  .banner__content1 {
    padding: 100px auto;
    text-align: center;
    align-items: center;
  }
  .banner__content1 h1 {
    font-size: 25px;
  }
  .bannerbox__outta {
    height: 300px;
  }
  .banner__btn {
    width: 200px;
    height: 50px;
    margin-top: 50px;
  }
  .banner__btn p {
    font-size: 20px;
    margin-top: 11px;
  }
}
