.choiceDisplay {
  margin: 78px 0;
  padding: 0px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.choiceDisplay h1 {
  margin: 50px;
  padding-bottom: 20px;
}
.choiceDisplay ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.choiceDisplay__secondary {
  padding: 50px;
  text-align: center;
}

.choiceDisplay__secondary p {
  width: 700px;
  font-size: 25px;
  font-weight: normal;
  padding-bottom: 25px;
  border-bottom: 2px solid lightblue;
}

@media (max-width: 879px) {
  .choiceDisplay__secondary p {
    font-size: 25px;
    width: 700px;
  }
}

@media (max-width: 768px) {
  .choiceDisplay__secondary p {
    font-size: 25px;
    width: 550px;
  }
}

@media (max-width: 740px) {
  .choiceDisplay__secondary p {
    font-size: 25px;
    width: 350px;
  }
}

@media (max-width: 375px) {
  .choiceDisplay__secondary p {
    font-size: 10px;
    width: 300px;
  }
}
