.side-drawer {
    height: 100%;
    background: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
  }
  
  .side-drawer.open {
    transform: translateX(0);
  }
  .side-drawer.close{
    transform: translateX(-100%);
  }
  .side-drawer ul {
    height: 90%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .side-drawer li {
    margin: 0.5rem 0;
    height: 50px;
    text-align: center;
  }
  .side-drawer a {
    color: #113067;
    text-decoration: none;
    font-size: 2.2rem;
  }
  .side-drawer a:hover,
  .side-drawer a:active {
    color: #fa923f;
    text-decoration: none;
  }
  .side-drawer .child-content li a {
    padding: 1rem;
    margin: 0.1rem;
  }
 .sideBar_Home{
   display: none;
 }
 .sideBar_links .active{ 
   background-color: none;
 }

  @media (min-width: 768px) {
    .side-drawer {
      display: none;
    }
  }
  