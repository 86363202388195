@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
html {
  font-size: 62.5%; /* 16px * 62.5 = 10px = 1rem */
  box-sizing: border-box;
}
html *,
.table,
table {
  border-collapse: unset;
  border-spacing: none;
  box-sizing: none;
}
body {
  font: 1.6rem Helvetica;
  height: 100vh;
  margin: 0;
  overflow-x:hidden;
}
button {
  font-size: 1.6rem;
}
.full-width {
  width: 100%;
}

#root {
  height: 100%;
}

/* Reviews */
ul.review {
  list-style-type: none;
  padding: 0;
}
.review li {
  margin: 1rem;
  margin-bottom: 2rem;
}
.review li > div {
  margin: 0.5rem;
}

/* Rating */
.rating span {
  color: #ffb901;
  font-size: 1.8rem;
  margin: 0.1rem;
}
.rating span:last-child {
  color: #444444;
  font-size: 1.4rem;
}
a > .rating > span:last-child {
  color: #0000ff;
}

/* Product Details */
.details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 1rem;
}
.details-image {
  flex: 2 1 60rem;
}
.details-image img {
  max-width: 60rem;
  width: 100%;
}
.details-info {
  flex: 1 1 30rem;
}
.details-action {
  flex: 1 1 30rem;
}

.details-info ul,
.details-action ul {
  list-style-type: none;
  padding: 0;
}
.details-info li,
.details-action li {
  margin-bottom: 1rem;
}
.back-to-result {
  padding: 1rem;
}
.details-action {
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  background-color: #f8f8f8;
  padding: 1rem;
}
.details-action ul li:last-child {
  display: flex;
  flex-direction: column;
}
.button {
  padding: 1rem;
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  cursor: pointer;
}
.button:hover {
  border: 0.1rem #404040 solid;
}
.button.primary {
  background-color: #f18901;
}
.button.secondary {
  background-color: #505090;
}
.button.primary:disabled,
.button.secondary:disabled {
  background-color: #dddddd;
}
.fb {
  background-color: #3a5a97;
}
.text-center {
  text-align: center;
}
/* start CartScreen.js 
start from className: .cart */

/* className here also used in PlaceOrderScreen.js
  */

.cartDiv_outta {
  margin: 100px 0;
  padding: 50px 10px;
  display: flex;
  flex-direction: column;
}
.cartDiv_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cart-list {
  display: flex;
  flex-direction: column;
}

.cart-list__Title {
  text-align: center;
}
.productPicked__div {
  margin: 50px 0;
  z-index: -1000;
}
.productPicked__div ul {
  margin: 0;
  padding: 0 20px;
}
.cart-list-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.card {
  z-index: 0;
}
.cart-list-container .card {
  margin: 10px;
  list-style: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 150px;
  height: auto;
  border: 1px solid #f0f0f0;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.35);
}
.card-title {
  font-size: 2rem;
  margin: 15px 0 0 0;
}
.cartDetail_choices {
  font-size: 2rem;
}
.card .img-thumbnail {
  border: none;
}
.cart-price {
  flex: 1 1;
  font-size: 2.5rem;
  text-align: right;
}
.cart-action {
  flex: 1 1 9rem;
  background-color: #f0f0f0;
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.35);
}
.cart-action .btn {
  background-color: #f36127;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.35);
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}
/* end of CartScreen.js file */

/* start of PaymentScreen.js 
*/

/*forms*/
div .form {
  border-top: none;
}
.form {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 1rem;
  height: 100%;
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 32rem;
  padding: 2rem;
  border-radius: 0.5rem;
  list-style: none;
}
.form-container li {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
}
input {
  margin: 10px 0 10px 0;
  padding: 5px;
  border: 1px solid grey;
  border-radius: 0.5rem;
}

/* start ProductionScreen.js */
/* content-margined also work in OrderScreen.js */
.content-margined {
  margin: 120px 20px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.product-header {
  width: inherit;
  margin: 0 20px;

  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.product-header h3,
.product-header button {
  margin: 0 0 50px 0;
}
.product-header .btn {
  background-color: #ff8000;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
}
.product-header .btn:active,
.product-header .btn:hover {
  background-color: #113067;
  color: #ffffff;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.35);
}
.CreateProduct_form-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  border-top: none;
}

/* ProductsScreen.js start from class: product-list_div__Content */

.product-list_div__Content {
  width: inherit;
  margin: 0 20px;

  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.table {
  width: auto;
  width: initial;
  font-size: 1.7rem;
  overflow-x: scroll;
}
th {
  text-align: left;
}
tbody > tr:nth-child(odd) {
  background-color: #f0f0f0;
}
td button {
  margin: 10px 0;
}
/* end of ProductsScreen.js 
I guess...
 */

/*start from checkoutsteps.js  */
.payment_outta {
  margin: 130px 0 0 0;
}
.checkout-steps {
  display: flex;
  width: 70%;
  margin: 1rem auto;
  font-size: 2.5vw;
}
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1 1;
  padding-top: 1rem;
}
.checkout-steps > div.active {
  border-top: 0.3rem #f08000 solid;
  color: #f08000;
}
/*end of checkoutsteps.js  */

/* start of OrderScreen.js */
.orderScreen__div {
  margin: 100px;
}
.productPicked__div .orderScreen_ShoppingcartDiv {
  margin: 10px;
}
.placeorder-action h5 {
  text-align: center;
  font-size: 1.5rem;
}
/* start PlaceOrderScreen.js 
from .placeorder
*/
.placeorder {
  display: flex;
  flex-grow: wrap;
  padding: 1rem;
  justify-content: space-between;
  font-size: 1.6rem;
}
.placeorder-info {
  flex: 3 1 60rem;
}
.placeorder-action {
  border: 0.1rem #c0c0c0;
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 2rem 3rem;
  font-size: 2rem;
}
.placeorder-info > div {
  border: 0.1rem #c0c0c0;
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 1rem;
  margin: 1rem;
}
.placeorder-info > div :first-child {
  margin-top: 0;
}
.placeorder-action > ul {
  padding: 0;
  list-style-type: none;
}
.placeorder-action > ul > li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.placeorder-action > ul > li:last-child {
  font-size: 2rem;
  color: #c04000;
  font-weight: bold;
}
.placeorder-actions-payment > div {
  width: 100%;
}

/* Start ProfileScreen.js
 */
.profile {
  margin: 140px 0;
  display: flex;
  flex-wrap: wrap;
}
.profile-info {
  flex: 1 1 30rem;
}
.profile-orders {
  flex: 3 1 60rem;
}

/* admin */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  padding: 1rem;
  list-style: null;
  z-index: 1;
  margin: 0;
  margin-top: 0.4rem;
  width: 100%;
  background: #fafbfc;
}
.dropdown:hover .dropdown-content {
  display: block;
}

.categories {
  padding: 0;

  display: flex;
  list-style: none;
}

.categories a:hover {
  background-color: #c0c0c0;
  padding: 1rem;
}

/* verify phone */
.verify-phone,
.verify-sms {
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 1rem auto;
}
/* shipping */
.shipping {
  display: inline-block;
  vertical-align: middle;
  padding: 1rem;
}

/* start OrderScreen.js */
.order-header h3 {
  font-size: 3rem;
}
div .order_btn__main {
  background-color: #f36127;
  
}

div .order_btn__secondary {
  background-color: black;
}

.bannerbox__outta {
  margin: 78px 0;
  padding: 0px;
  height: 600px;
  width: 100%;
  background-image: url(/static/media/food1.b6ea7075.jpeg);
  background-position: left center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.bannerboxInner_opacity {
  width: inherit;
  height: inherit;
  background: rgb(232, 232, 232, 0.6);
}
.banner__content1 {
  padding: 10% 20% 10% 20%;
  text-align: center;
}
.banner__content1 h1 {
  font-size: 700%;
  font-weight: 800;
  letter-spacing: 1.4px;
  word-spacing: 2px;
  width: 100%;
}
.banner__btn {
  background-color: #f36127;
  cursor: pointer;
  width: 250px;
  height: 70px;
  margin-top: 50px;
  border-radius: 15px;
  text-transform: uppercase;
  text-align: center;
  box-shadow: -1px 12px 14px 0px rgba(0, 0, 0, 0.7);
}

.banner__btn p {
  font-size: 25px;
  font-weight: bolder;
  margin-top: 20px;
  color: white;
}
.banner__btn p:active, .banner__btn p:hover{
  color: black;
}

@media all and (max-width: 375px){
  .banner__content1 {
    padding: 100px auto;
    text-align: center;
    align-items: center;
  }
  .banner__content1 h1 {
    font-size: 25px;
  }
  .bannerbox__outta {
    height: 300px;
  }
  .banner__btn {
    width: 200px;
    height: 50px;
    margin-top: 50px;
  }
  .banner__btn p {
    font-size: 20px;
    margin-top: 11px;
  }
}

.discountBox__outta {
  width: 100%;
  padding: 0 90px 90px 90px;
}

.discountBox__inner {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.discountBox__inner img {
  border: none;
}
.img-thumbnail {
  border: none;
  width: 180px;
  height: 180px;
}
.discountBox__inner p {
    font-size: x-large;
    width: 350px;
    margin: 30px;
}
.discount__btn{
    background-color: #f36127;
    color: white;
    cursor: pointer;
    width: 350px;
    height: 70px;
    
    border-radius: 15px;
    text-transform: uppercase;
    text-align: center;
    box-shadow: -1px 12px 14px 0px rgba(0, 0, 0, 0.7);

}
.discount__btn p{
    font-size: 23px;
    font-weight: bolder;
    margin:20px 0 0 0 ;
    color: white;
}
.discount__btn p:active, .discount__btn p:hover{
    color: black;
}

.orderStep__outta {
  margin: 40px auto;
  padding: 1em;
  width: 100%;
}
.orderStep__outta h2 {
  text-align: center;
  font-size: 5rem;
}
.orderStep__inner {
  margin: 40px;
  padding: 100px auto;
  display: flex;

  flex-direction: column;
}
.orderStep__div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.middle_div{
  background-color: #F8F8F8; 
  border-radius: 10px;  
  margin: 20px 20px;
}
.content {
  font-size: 18px;
  width: 375px;
  margin: 20px;
}
.content2 {
  font-size: 18px;
  width: 375px;
  margin: 30px 0 0 20px;
  text-align: right;
}

figure {
  text-align: center;
  font-size: 30px;
}
figure .img-thumbnail{
    border: none;
    min-width: 100%;
}
@media all and (max-width: 375px){
    content {
        font-size: 12px;
        width: 220px;
        margin: 12px 0 0 10px;
      }
      content2 {
        font-size: 12px;
        width: 220px;
        margin: 22px 0 0 5px;
        text-align: right;
      }
      figure{
          font-size: 10px;
      }
      .orderStep__outta h2 {
        font-size: 3rem;
      }
}
.whyUs__outta{
  margin: 40px 25px;
  display: flex;
  flex-direction: column;

}
.whyUs__title{
  margin: 40px;
  text-align: center;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  
}
.whyUs__title h2 {
  font-size: 50px;
  text-transform: uppercase;
}

.whyUs__content{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap ;
}

.whyus__Ans1,
.whyus__Ans2,
.whyus__Ans3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.whyus__Ans1 p,
.whyus__Ans2 p,
.whyus__Ans3 p {
  font-size: 25px;
  width: 80%;
  text-align: -webkit-left;
}

.homePageScreen{
    margin: 0;
    padding: 0;
}
.card_div .card{
    border: 0px white;
}
.card__style{
    width: 220px;
    height: 280px;
    margin-bottom: 80px;
}
.card__style .card-header{
    border-bottom: 0px white;
    background-color: white;
}
.cardTitle_style{
    margin-top: 25px;

}
.cardTitle_style h2 {
   font-weight: bolder;
   text-transform: uppercase;
}
.img_style{
    width: 200px;
    height: 200px;
}
.cardText__style{
   font-size: 20px;
   letter-spacing: 2px;
   font-family: sans-serif;
   margin-bottom: 60px;
}

.borderBottom{ 
    border-bottom: 3px solid #113067;
}
.borderBottomPlus{ 
    border-bottom: 9px solid #fa923f;
}


@media (max-width: 879px){
    .img_style{
        width: 180px;
        height: 180px;
    }
    .card__style{
        width: 200px;
        height: 260px;
    }
    .cardTitle_style h2 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
     }
     .cardText__style{
        font-size: 20px;
        letter-spacing: 0px;
        font-family: sans-serif;
     }
}

@media (max-width: 768px){
    .img_style{
        width: 150px;
        height: 150px;
    }
    .card__style{
        width: 180px;
        height: 230px;
    }
    .cardTitle_style h2 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
     }
     .cardText__style{
        font-size: 20px;
        letter-spacing: 0px;
        font-family: sans-serif;
     }
}
@media (max-width: 740px){
    .img_style{
        width: 140px;
        height: 140px;
    }
    .card__style{
        width: 180px;
        height: 220px;
    }
    .cardTitle_style h2 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
     }
     .cardText__style{
        font-size: 20px;
        letter-spacing: 0px;
        font-family: sans-serif;
     }
}
@media (max-width: 375px){
    .img_style{
        width: 75px;
        height: 75px;
    }
    .card__style{
        width: 100px;
        height: 185px;
        margin-bottom: 1px;
    }
    .cardTitle_style h2 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 10px;
     }
     .cardText__style{
        font-size: 10px;
        letter-spacing: 0px;
        font-family: sans-serif;
     }
}
.menuBtn__Outta {
  display: flex;
  justify-content: space-around;
  padding: 35px;
  margin-bottom: 20px;
  background-color: #fafbfc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.menuBtn__Outta p {
  font-size: 20px;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  margin: 0;
}

.menuBtn__btn {
  font-size: 20px;
  color: white;
  background-color: #113067;
  border: 1px solid #113067;
  border-radius: 5px;
  width: 200px;
  height: 36px;
  text-align: center;
  text-decoration: none;
}
.menuBtn__btn span{
  color: white;
}
.menuBtn__btn span:hover, .menuBtn__btn span:active{
  color: #FFC933;
} 

.choiceDisplay {
  margin: 78px 0;
  padding: 0px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.choiceDisplay h1 {
  margin: 50px;
  padding-bottom: 20px;
}
.choiceDisplay ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.choiceDisplay__secondary {
  padding: 50px;
  text-align: center;
}

.choiceDisplay__secondary p {
  width: 700px;
  font-size: 25px;
  font-weight: normal;
  padding-bottom: 25px;
  border-bottom: 2px solid lightblue;
}

@media (max-width: 879px) {
  .choiceDisplay__secondary p {
    font-size: 25px;
    width: 700px;
  }
}

@media (max-width: 768px) {
  .choiceDisplay__secondary p {
    font-size: 25px;
    width: 550px;
  }
}

@media (max-width: 740px) {
  .choiceDisplay__secondary p {
    font-size: 25px;
    width: 350px;
  }
}

@media (max-width: 375px) {
  .choiceDisplay__secondary p {
    font-size: 10px;
    width: 300px;
  }
}

.recipeCard_outta{
  margin: 40px 0;
}
.recipeCard_div:hover {
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

.recipeCard_body {
  padding: 5rem 2rem !important;
  text-align: start;
  font-size: 15px;
}

.card-img-top {
  transform: scale(1);
  transition: transform 0.8s ease-in;
  
} 

.card-img-top:hover {
  transform: scale(1.3);
}
.recipeCard_div a {
  background: none;
  border: none;
} .card a:hover{ 
    text-decoration: none;
}
.overflow{
    overflow: hidden;
}

@media all and (max-width:375px){
    .card-img-top {
        transform: none;
        transition: none;
        ;
      } 
      
      .card-img-top:hover {
        transform: none;
      }
}
.accordion__section{ 
    display: flex;
    flex-direction: column;
}

.accordion{
    background-color: #fafbfc;
    color: #444;
    cursor: pointer;
    padding: 18px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.accordion:hover,
.accordion_active{
    background-color:#FAFBFC;
}

.accordion__title{
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 20px;
}

.accordion__icon{
    transition: transform 0.6s ease;

}

.rotate{
    transform: rotate(90deg);
}

.accordion_content_outta{
    background-color: white;
    overflow: hidden;
    transition: max-height .6s ease; 
}

.accordion__content{
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 14px;
}

@media all and (min-width:375px){
    .recipeCard_changes {
        transform: none;
        transition: none;
        ;
      } 
      
      .recipeCard_changes:hover {
        transform: none;
      }
}
.menuPageScreen{
  margin: 0;
  padding: 0;
}

.side-drawer {
    height: 100%;
    background: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
  }
  
  .side-drawer.open {
    transform: translateX(0);
  }
  .side-drawer.close{
    transform: translateX(-100%);
  }
  .side-drawer ul {
    height: 90%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .side-drawer li {
    margin: 0.5rem 0;
    height: 50px;
    text-align: center;
  }
  .side-drawer a {
    color: #113067;
    text-decoration: none;
    font-size: 2.2rem;
  }
  .side-drawer a:hover,
  .side-drawer a:active {
    color: #fa923f;
    text-decoration: none;
  }
  .side-drawer .child-content li a {
    padding: 1rem;
    margin: 0.1rem;
  }
 .sideBar_Home{
   display: none;
 }
 .sideBar_links .active{ 
   background-color: none;
 }

  @media (min-width: 768px) {
    .side-drawer {
      display: none;
    }
  }
  
.backdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.3);
    z-index: 100;
}
.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 35px;
    width: 40px;
    background: transparent;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    background: #BEC9DB;
    opacity: 0.8;
  }
  .toggle-button:focus {
    outline: none;
  }
  .toggle-button__line{
      width: 30px;
      padding: 2.5px;
      background: #113067;
      margin-left: .5rem;
      border-radius: 5px;
  }
  .toggle-button:active, .toggle-button:hover{
    background-color:  #ff8000;
    opacity: 0.7;
  }
.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 15px;
  outline: none;
  padding: 20px;
  width: 350px;
    height: 400px;background-color: #f36127;
}
.mymodal_title{
  width: 300px;
  height: 50px;
  color: white;
  text-align: center;
  top: 50%;
  left: 50%;
}
.mymodal_title h1{
  margin: 20px 0;
  letter-spacing: 1.4px;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}
.mymodal li, .mymodal a{
  font-size: 2.5rem;
  padding: 5px 0;
  color: white;
}
.mymodal a:hover, .mymodal a:active{
  text-decoration: none;
  color: #000000;text-shadow: 1px 3px 2px rgb(255,255,255,0.65);
  
}




.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.categories {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbar {
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: #fafbfc;
  overflow-x: hidden;
  position: fixed;
  z-index: 9999; 
}
.toolbar_admin{
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: #fafbfc;
}
.toolbar__nav {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
  color: #113067;
}

.toolbar__Logo {
  width: 75px;
  margin-left: 3.5rem;
}
.toolbar__logo span {
  color: #ff8000;
}
.toolbar__logo a {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: -3.9px;
  display: inline-flex;
}
.toolbar__logo p{
  font-size: 30px;
  margin-top: 25px;
}

.spacer {
  flex: 1 1;
}
.toolbar_nav-items{
  margin-top: 20px;
}
.toolbar_nav-items ul {
  list-style: none;
  margin: 0px 15px;
  padding: 0;
  display: flex;
}

.toolbar_nav-items li {
  padding: 0 0.5rem;
}

.toolbar_nav-items a {
  color: #113067;
  text-decoration: none;
  font-size: 20px;
  padding: 0 5px;
}
.toolbar__logo a:hover,
.toolbar__logo a:active,
.toolbar_nav-items a:hover,
.toolbar_nav-items a:active {
  color: #fa923f;
}
.toolbar_nav-item{
  padding-right:1.5rem;
}
.btn-warning:active, .btn-warning:hover{
  color: black;
}

.footerLink_abt1:active, .footerLink_abt1:hover{
 background-color: inherit;
}

@media (max-width: 768px) {
  .toolbar_nav-items {
    display: none;
  }
}

@media (min-width: 769px) {
  .toolbar__toggle-button {
    display: none;
  }
  .toolbar__logo {
    margin-left: 0;
  }
}

@media all and (max-width: 375px) {
}

.main-footer {
  color: white;
  background-color: #113067;
  padding-top: 3em;
  position: relative;
  bottom: 0;
  width: 100%;
}
.footer_inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.footer_logo {
  width: 85px;
  height: auto;
}


.footerLink {
  color: #fafbfc;
  font-size: 1.19rem;
}
.footerLink:hover {
  text-decoration: none;
  color: #F36127;
}
.footer_detail_div, .footer_detail_div h4{
  font-size: 1;
  padding: 1.3vh 0;
}
.helpCenter_btn__content {
  margin: 30px 50px;
  text-align: left;
}
.helpCenter_btn__content p {
  text-align: left;
  text-align: left;
  font-size: 18px;
}

.innerContent__outta {
  margin: 140px 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.help_title {
  height: 250px;
  width: inherit;
  background-color: #f36127;
  color: white;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
}
.help_title h1 {
    text-align: center;
    margin: 91px 0;
    font-size: 5.5rem;
}
.qa_div {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin: 20px 0 50px 0;
}
.qa_div h2 {
  margin: 20px 0;
  font-size: 25px;
  
}
@media (max-width: 700px) {
  .innerContent__outta {
    margin: 78px 10px;
  }
}

.delivery__outta {
  margin: 78px 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow-x: hidden;
}

.delivery_header {
  width: 100%;
  height: 290px;
  background-image: url(/static/media/delivery.887db4be.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.delivery__outta h1 {
  padding: 20px 0;

  font-size: 4.5rem;
  word-spacing: 0.2px;
  text-shadow: 0px 2px 2.5px rgba(0, 0, 0, 0.65);
}
.delivery__outta_secondary {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.delivery__outta_secondary h2 {
  padding: 20px 5px;
}
.delivery__outta_secondary h4 {
  padding: 0px 5px;
}

.delivery__content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 50px 0;
}
.delivery_detail h4 {
  font-size: 2rem;
  text-shadow: 1px 2px 2px rgba(7, 5, 5, 0.5);
}

.delivery_detail p {
  font-size: 1.3rem;
  text-align: left;
}
.delivery_img {
  width: auto;
  height: 130px;
  margin: 15px 0;
  border-radius: 50%;
  border: 1px solid #bdc3c7;
  box-shadow: 2px 6px 7px 1px rgba(0, 0, 0, 0.31);
}
.delivery__content_secondary p {
  font-size: 18px;
  font-weight: 700;
}
.delivery__content_secondary span {
  text-decoration: underline;
}
.delivery__content_secondary small {
  text-decoration: underline;
  font-size: small;
  font-style: italic;
}
.delivery_text_bottom {
  padding: 50px;
}
.delivery_text_bottom p {
  font-size: 3.5rem;
  text-shadow: 0px 2.5px 1.5px rgb(0 0 0);
  color: #F36127;
}

.aboutUs__outta {
  margin: 78px 0;
  padding: 0;
  display: block;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
}
.aboutus_header {
  padding: 40px 0;
  width: inherit;
  background-color: #113067;
  color: white;
}
.aboutUs__outta h1 {
  font-size: 4rem;
  text-align: center;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.75);
}
.aboutUs__outta_secondary {
  display: flex;
  justify-content: center;
}
.aboutUs__inner {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.aboutUs__content_Heading {
  margin: 40px 0px;
  padding: 40px 0;
  width: 100%;
}
.aboutUs__content_Heading h2{
font-size: 3rem;
color: #f36127;
text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.95);
}
.aboutUs__content_Heading h6 {
  padding: 30px 15%;
  font-size: 1.7rem;
}
.aboutUs__content_secondary {
  padding: 40px 0;
  width: 100%;
  background-color: #113067;
  color: white;
  border-radius: 5px;
}
.aboutUs__content_secondary h3{
  font-size: 3rem;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.75);
}
.aboutUs__content_third {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 30px 0 50px 0 ;
}
.abtus_detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.abtUs_img_div{
  margin:10px 0;
}
.abtUs_img{
    width: auto;
    height: 130px;
    border-radius: 50%;
    border: 1px solid #bdc3c7;
    box-shadow: 2px 6px 7px 1px rgba(0,0,0,0.31);
}
.abtus_detail h4{
  font-size: 2rem;
  letter-spacing: 0.1px;color: #f36127;
  text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.95);
  
}
.abtus_detail p{
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.3;
  
}

/* @media all and(min-width:375px){
    .aboutUs__inner p{
      font-size: .5rem;
    }
}  */
.specialOffer__outta {
  margin: 78px 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow-x: hidden;
}
.specialOffer__header {
  height: 350px;
  background-image: url(/static/media/specialOfferHeader.af2aa42d.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
}
.specialOffer__header h1 {
  font-size: 4.5rem;
  word-spacing: 0.2px;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}
.specialOffer__header span {
  font-size: 3rem;
  font-style: oblique;
}
.specialOffer__header h2 {
  margin: 20px 0;
  width: 100%;
  height: 10%;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}

.specialOffer__header small {
  font-style: oblique;
}
.specialOffer__header a {
  background-color: #f36127;
  color: white;
  cursor: pointer;
  width: 200px;
  height: 50px;
  margin: 20px 0;
  border-radius: 15px;
  text-transform: uppercase;
  text-align: center;
  align-self: center;
  box-shadow: -1px 12px 14px 0px rgba(0, 0, 0, 0.7);
}
.specialOffer__header p {
  font-size: 20px;
  font-weight: bolder;
  margin-top: 10px;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}
.specialOffer__inner_main {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.specialOffer__inner_main h2 {
  margin: 20px 0;
  word-spacing: 0.2px;
  text-shadow: 1px 1.2px 2px rgba(0, 0, 0, 0.45);
}

.specialOffer__content_first{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 0 50px 0;
}
.specialOffer__details{
    margin: 10px 5px;
    box-shadow: 1px 1.2px 2px rgba(0, 0, 0, 0.45);
}
.specialOffer__detail_img{
    width: 200px;
}
.specialOffer__details p span{
    font-weight: 900;
    font-size: 15px;
}
.specialOffer__details p {
    font-size: 15px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* **Below** 
Page: Category 
js file : HomeScreen.js
start with className: category_product__outta*/

.category_product__outta {
    margin: 80px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .category_product__inner {
    width: inherit;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .cAndp__title {
    font-size: 3.5rem;
  }
  /* 
  filter */
  
  .filter_div_outta {
    margin: 50px 5px;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .filter_search_btn {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 5px 0px;
    padding: 0px;
    width: 300px;
  }
  .filterInput_label {
    font-size: 2.2rem;
    margin: 20px 0;
    padding: 0;
  }
  /* @media (min-width:375px){
    .filter_search_btn {
      margin: 5px 0px;
      padding: 0 5px;
    }
    .filterInput_label {
      font-size: 1.5rem;
    }
  } */
  /* end of search engine 
  
  Below: div for showing products */
  .show_products{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .show_products li {
    list-style-type: none;
    padding: 0;
    flex: 0 1 20rem;
    margin: 1rem;
    height: auto;
    width: 200px;
    border: 0.1rem #c0c0c0 solid;
    border-radius: 10px;
  }
  .product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .product a:hover, .product a:active{
      color: #f36127;
      text-decoration: none;
  }
  .product-name {
    font-size: 2rem;
    font-weight: bold;
  }
  .product-brand {
    font-size: 1.9rem;
    color: #808080;
  }
  .product-price {
    font-size: 2rem;
    font-weight: bold;
  }
  .product img{
    border: none;
  }
  
  .product-rating {
    margin-bottom: 1rem;
  }
/* Variables */
:root {
  --gray-offset: rgba(0, 0, 0, 0.03);
  --gray-border: rgba(0, 0, 0, 0.15);
  --gray-light: rgba(0, 0, 0, 0.4);
  --gray-mid: rgba(0, 0, 0, 0.7);
  --gray-dark: rgba(0, 0, 0, 0.9);
  --body-color: var(--gray-mid);
  --headline-color: var(--gray-dark);
  --accent-color: #0066f0;
  --body-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  --radius: 6px;
  --logo-image: url("https://storage.googleapis.com/stripe-sample-images/KAVHOLM.svg");
  --form-width: 343px;
}

.sr-combo-inputs {
  margin: 20px 0;
}

.sr-input {
  font-size: 16px;
}

.sr-card-element {
  padding-top: 12px;
}

.btn-stripe {
  font-size: 16px;
  width: 100%;
  background: #0066f0;
  background: var(--accent-color);
  border-radius: 6px;
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
.btn-stripe:hover {
  -webkit-filter: contrast(115%);
          filter: contrast(115%);
}
.btn-stripe:active {
  transform: translateY(0px) scale(0.98);
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}
.btn-stripe:disabled {
  opacity: 0.5;
  cursor: none;
}

.sr-text {
  max-width: 825px;
  margin: 0 auto;
  font-size: 14px;
  background: white;
  color: #6a7c94;
}


body {
    background: #f2f2f2;
    padding: 20px;
  }
  
  .input-tag {
    background: white;
    border: 1px solid #d6d6d6;
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 5px 0;
  }
  
  .input-tag input {
    border: none;
    width: 100%;
  }
  
  .input-tag__tags {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .input-tag__tags li {
    align-items: center;
    background: #85A3BF;
    border-radius: 2px;
    color: white;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
  }
  
  .input-tag__tags li button {
    align-items: center;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: #333333;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    height: 15px;
    justify-content: center;
    line-height: 0;
    margin-left: 8px;
    padding: 0;
    transform: rotate(45deg);
    width: 15px;
  }
  
  .input-tag__tags li.input-tag__tags__input {
    background: none;
    flex-grow: 1;
    padding: 0;
  }
html, body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: #1A1A1D;
}

.joinInnerContainer {
  width: 20%;
}

.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
}

.heading {
  color: white;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

.button {
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #2979FF;
    padding: 20px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
  }


}

button:focus {
  outline: 0;
}
.infoBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2979FF;
  border-radius: 4px 4px 0 0;
  height: 60px;
  width: 100%;
}

.leftInnerContainer {
  flex: 0.5 1;
  display: flex;
  align-items: center;
  margin-left: 5%;
  color: white;
}

.rightInnerContainer {
  display: flex;
  flex: 0.5 1;
  justify-content: flex-end;
  margin-right: 5%;
}

.onlineIcon {
  margin-right: 5%;
}

.form {
  display: flex;
  border-top: 2px solid #D3D3D3;
}

.input {
  border: none;
  border-radius: 0;
  padding: 5%;
  width: 80%;
  font-size: 1.2em;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.sendButton {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #2979FF;
  padding: 20px;
  display: inline-block;
  border: none;
  width: 20%;
}
.messages {
  padding: 5% 0;
  overflow: auto;
  flex: auto;
}
.messageBox {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #2979FF;
}

.backgroundLight {
  background: #F3F3F3;
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  color: white;
  height: 60%;
  justify-content: space-between;
}

.activeContainer {
  display: flex;
  align-items: center;
  margin-bottom: 50%;
}

.activeItem {
  display: flex;
  align-items: center;
}

.activeContainer img {
  padding-left: 10px;
}

.textContainer h1 {
  margin-bottom: 0px;
}

@media (min-width: 320px) and (max-width: 1200px) {
  .textContainer {
    display: none;
  }
}
.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1A1A1D;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 8px;
  height: 60%;
  width: 35%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .outerContainer {
    height: 100%;
  }

  .container {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 480px) and (max-width: 1200px) {
  .container {
    width: 60%;
  }
}

