.recipeCard_outta{
  margin: 40px 0;
}
.recipeCard_div:hover {
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

.recipeCard_body {
  padding: 5rem 2rem !important;
  text-align: start;
  font-size: 15px;
}

.card-img-top {
  transform: scale(1);
  transition: transform 0.8s ease-in;
  
} 

.card-img-top:hover {
  transform: scale(1.3);
}
.recipeCard_div a {
  background: none;
  border: none;
} .card a:hover{ 
    text-decoration: none;
}
.overflow{
    overflow: hidden;
}

@media all and (max-width:375px){
    .card-img-top {
        transform: none;
        transition: none;
        ;
      } 
      
      .card-img-top:hover {
        transform: none;
      }
}