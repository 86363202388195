.toolbar {
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: #fafbfc;
  overflow-x: hidden;
  position: fixed;
  z-index: 9999; 
}
.toolbar_admin{
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: #fafbfc;
}
.toolbar__nav {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
  color: #113067;
}

.toolbar__Logo {
  width: 75px;
  margin-left: 3.5rem;
}
.toolbar__logo span {
  color: #ff8000;
}
.toolbar__logo a {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: -3.9px;
  display: inline-flex;
}
.toolbar__logo p{
  font-size: 30px;
  margin-top: 25px;
}

.spacer {
  flex: 1;
}
.toolbar_nav-items{
  margin-top: 20px;
}
.toolbar_nav-items ul {
  list-style: none;
  margin: 0px 15px;
  padding: 0;
  display: flex;
}

.toolbar_nav-items li {
  padding: 0 0.5rem;
}

.toolbar_nav-items a {
  color: #113067;
  text-decoration: none;
  font-size: 20px;
  padding: 0 5px;
}
.toolbar__logo a:hover,
.toolbar__logo a:active,
.toolbar_nav-items a:hover,
.toolbar_nav-items a:active {
  color: #fa923f;
}
.toolbar_nav-item{
  padding-right:1.5rem;
}
.btn-warning:active, .btn-warning:hover{
  color: black;
}

.footerLink_abt1:active, .footerLink_abt1:hover{
 background-color: inherit;
}

@media (max-width: 768px) {
  .toolbar_nav-items {
    display: none;
  }
}

@media (min-width: 769px) {
  .toolbar__toggle-button {
    display: none;
  }
  .toolbar__logo {
    margin-left: 0;
  }
}

@media all and (max-width: 375px) {
}
