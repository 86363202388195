.whyUs__outta{
  margin: 40px 25px;
  display: flex;
  flex-direction: column;

}
.whyUs__title{
  margin: 40px;
  text-align: center;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  
}
.whyUs__title h2 {
  font-size: 50px;
  text-transform: uppercase;
}

.whyUs__content{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap ;
}

.whyus__Ans1,
.whyus__Ans2,
.whyus__Ans3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.whyus__Ans1 p,
.whyus__Ans2 p,
.whyus__Ans3 p {
  font-size: 25px;
  width: 80%;
  text-align: -webkit-left;
}
