.menuBtn__Outta {
  display: flex;
  justify-content: space-around;
  padding: 35px;
  margin-bottom: 20px;
  background-color: #fafbfc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.menuBtn__Outta p {
  font-size: 20px;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  margin: 0;
}

.menuBtn__btn {
  font-size: 20px;
  color: white;
  background-color: #113067;
  border: 1px solid #113067;
  border-radius: 5px;
  width: 200px;
  height: 36px;
  text-align: center;
  text-decoration: none;
}
.menuBtn__btn span{
  color: white;
}
.menuBtn__btn span:hover, .menuBtn__btn span:active{
  color: #FFC933;
} 
