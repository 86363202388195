.orderStep__outta {
  margin: 40px auto;
  padding: 1em;
  width: 100%;
}
.orderStep__outta h2 {
  text-align: center;
  font-size: 5rem;
}
.orderStep__inner {
  margin: 40px;
  padding: 100px auto;
  display: flex;

  flex-direction: column;
}
.orderStep__div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.middle_div{
  background-color: #F8F8F8; 
  border-radius: 10px;  
  margin: 20px 20px;
}
.content {
  font-size: 18px;
  width: 375px;
  margin: 20px;
}
.content2 {
  font-size: 18px;
  width: 375px;
  margin: 30px 0 0 20px;
  text-align: right;
}

figure {
  text-align: center;
  font-size: 30px;
}
figure .img-thumbnail{
    border: none;
    min-width: 100%;
}
@media all and (max-width: 375px){
    content {
        font-size: 12px;
        width: 220px;
        margin: 12px 0 0 10px;
      }
      content2 {
        font-size: 12px;
        width: 220px;
        margin: 22px 0 0 5px;
        text-align: right;
      }
      figure{
          font-size: 10px;
      }
      .orderStep__outta h2 {
        font-size: 3rem;
      }
}