.main-footer {
  color: white;
  background-color: #113067;
  padding-top: 3em;
  position: relative;
  bottom: 0;
  width: 100%;
}
.footer_inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.footer_logo {
  width: 85px;
  height: auto;
}


.footerLink {
  color: #fafbfc;
  font-size: 1.19rem;
}
.footerLink:hover {
  text-decoration: none;
  color: #F36127;
}
.footer_detail_div, .footer_detail_div h4{
  font-size: 1;
  padding: 1.3vh 0;
}