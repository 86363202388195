.specialOffer__outta {
  margin: 78px 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow-x: hidden;
}
.specialOffer__header {
  height: 350px;
  background-image: url(./FooterAsset/specialOfferHeader.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
}
.specialOffer__header h1 {
  font-size: 4.5rem;
  word-spacing: 0.2px;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}
.specialOffer__header span {
  font-size: 3rem;
  font-style: oblique;
}
.specialOffer__header h2 {
  margin: 20px 0;
  width: 100%;
  height: 10%;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}

.specialOffer__header small {
  font-style: oblique;
}
.specialOffer__header a {
  background-color: #f36127;
  color: white;
  cursor: pointer;
  width: 200px;
  height: 50px;
  margin: 20px 0;
  border-radius: 15px;
  text-transform: uppercase;
  text-align: center;
  align-self: center;
  box-shadow: -1px 12px 14px 0px rgba(0, 0, 0, 0.7);
}
.specialOffer__header p {
  font-size: 20px;
  font-weight: bolder;
  margin-top: 10px;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}
.specialOffer__inner_main {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.specialOffer__inner_main h2 {
  margin: 20px 0;
  word-spacing: 0.2px;
  text-shadow: 1px 1.2px 2px rgba(0, 0, 0, 0.45);
}

.specialOffer__content_first{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 0 50px 0;
}
.specialOffer__details{
    margin: 10px 5px;
    box-shadow: 1px 1.2px 2px rgba(0, 0, 0, 0.45);
}
.specialOffer__detail_img{
    width: 200px;
}
.specialOffer__details p span{
    font-weight: 900;
    font-size: 15px;
}
.specialOffer__details p {
    font-size: 15px;
}
