html {
  font-size: 62.5%; /* 16px * 62.5 = 10px = 1rem */
  box-sizing: border-box;
}
html *,
.table,
table {
  border-collapse: unset;
  border-spacing: none;
  box-sizing: none;
}
body {
  font: 1.6rem Helvetica;
  height: 100vh;
  margin: 0;
  overflow-x:hidden;
}
button {
  font-size: 1.6rem;
}
.full-width {
  width: 100%;
}

#root {
  height: 100%;
}

/* Reviews */
ul.review {
  list-style-type: none;
  padding: 0;
}
.review li {
  margin: 1rem;
  margin-bottom: 2rem;
}
.review li > div {
  margin: 0.5rem;
}

/* Rating */
.rating span {
  color: #ffb901;
  font-size: 1.8rem;
  margin: 0.1rem;
}
.rating span:last-child {
  color: #444444;
  font-size: 1.4rem;
}
a > .rating > span:last-child {
  color: #0000ff;
}

/* Product Details */
.details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 1rem;
}
.details-image {
  flex: 2 1 60rem;
}
.details-image img {
  max-width: 60rem;
  width: 100%;
}
.details-info {
  flex: 1 1 30rem;
}
.details-action {
  flex: 1 1 30rem;
}

.details-info ul,
.details-action ul {
  list-style-type: none;
  padding: 0;
}
.details-info li,
.details-action li {
  margin-bottom: 1rem;
}
.back-to-result {
  padding: 1rem;
}
.details-action {
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  background-color: #f8f8f8;
  padding: 1rem;
}
.details-action ul li:last-child {
  display: flex;
  flex-direction: column;
}
.button {
  padding: 1rem;
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  cursor: pointer;
}
.button:hover {
  border: 0.1rem #404040 solid;
}
.button.primary {
  background-color: #f18901;
}
.button.secondary {
  background-color: #505090;
}
.button.primary:disabled,
.button.secondary:disabled {
  background-color: #dddddd;
}
.fb {
  background-color: #3a5a97;
}
.text-center {
  text-align: center;
}
/* start CartScreen.js 
start from className: .cart */

/* className here also used in PlaceOrderScreen.js
  */

.cartDiv_outta {
  margin: 100px 0;
  padding: 50px 10px;
  display: flex;
  flex-direction: column;
}
.cartDiv_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cart-list {
  display: flex;
  flex-direction: column;
}

.cart-list__Title {
  text-align: center;
}
.productPicked__div {
  margin: 50px 0;
  z-index: -1000;
}
.productPicked__div ul {
  margin: 0;
  padding: 0 20px;
}
.cart-list-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.card {
  z-index: 0;
}
.cart-list-container .card {
  margin: 10px;
  list-style: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 150px;
  height: auto;
  border: 1px solid #f0f0f0;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.35);
}
.card-title {
  font-size: 2rem;
  margin: 15px 0 0 0;
}
.cartDetail_choices {
  font-size: 2rem;
}
.card .img-thumbnail {
  border: none;
}
.cart-price {
  flex: 1 1;
  font-size: 2.5rem;
  text-align: right;
}
.cart-action {
  flex: 1 1 9rem;
  background-color: #f0f0f0;
  border: 0.1rem #808080 solid;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.35);
}
.cart-action .btn {
  background-color: #f36127;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.35);
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
}
/* end of CartScreen.js file */

/* start of PaymentScreen.js 
*/

/*forms*/
div .form {
  border-top: none;
}
.form {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 1rem;
  height: 100%;
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 32rem;
  padding: 2rem;
  border-radius: 0.5rem;
  list-style: none;
}
.form-container li {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
}
input {
  margin: 10px 0 10px 0;
  padding: 5px;
  border: 1px solid grey;
  border-radius: 0.5rem;
}

/* start ProductionScreen.js */
/* content-margined also work in OrderScreen.js */
.content-margined {
  margin: 120px 20px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.product-header {
  width: inherit;
  margin: 0 20px;

  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.product-header h3,
.product-header button {
  margin: 0 0 50px 0;
}
.product-header .btn {
  background-color: #ff8000;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.65);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
}
.product-header .btn:active,
.product-header .btn:hover {
  background-color: #113067;
  color: #ffffff;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.35);
}
.CreateProduct_form-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  border-top: none;
}

/* ProductsScreen.js start from class: product-list_div__Content */

.product-list_div__Content {
  width: inherit;
  margin: 0 20px;

  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.table {
  width: initial;
  font-size: 1.7rem;
  overflow-x: scroll;
}
th {
  text-align: left;
}
tbody > tr:nth-child(odd) {
  background-color: #f0f0f0;
}
td button {
  margin: 10px 0;
}
/* end of ProductsScreen.js 
I guess...
 */

/*start from checkoutsteps.js  */
.payment_outta {
  margin: 130px 0 0 0;
}
.checkout-steps {
  display: flex;
  width: 70%;
  margin: 1rem auto;
  font-size: 2.5vw;
}
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1 1;
  padding-top: 1rem;
}
.checkout-steps > div.active {
  border-top: 0.3rem #f08000 solid;
  color: #f08000;
}
/*end of checkoutsteps.js  */

/* start of OrderScreen.js */
.orderScreen__div {
  margin: 100px;
}
.productPicked__div .orderScreen_ShoppingcartDiv {
  margin: 10px;
}
.placeorder-action h5 {
  text-align: center;
  font-size: 1.5rem;
}
/* start PlaceOrderScreen.js 
from .placeorder
*/
.placeorder {
  display: flex;
  flex-grow: wrap;
  padding: 1rem;
  justify-content: space-between;
  font-size: 1.6rem;
}
.placeorder-info {
  flex: 3 1 60rem;
}
.placeorder-action {
  border: 0.1rem #c0c0c0;
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 2rem 3rem;
  font-size: 2rem;
}
.placeorder-info > div {
  border: 0.1rem #c0c0c0;
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 1rem;
  margin: 1rem;
}
.placeorder-info > div :first-child {
  margin-top: 0;
}
.placeorder-action > ul {
  padding: 0;
  list-style-type: none;
}
.placeorder-action > ul > li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.placeorder-action > ul > li:last-child {
  font-size: 2rem;
  color: #c04000;
  font-weight: bold;
}
.placeorder-actions-payment > div {
  width: 100%;
}

/* Start ProfileScreen.js
 */
.profile {
  margin: 140px 0;
  display: flex;
  flex-wrap: wrap;
}
.profile-info {
  flex: 1 1 30rem;
}
.profile-orders {
  flex: 3 1 60rem;
}

/* admin */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  padding: 1rem;
  list-style: null;
  z-index: 1;
  margin: 0;
  margin-top: 0.4rem;
  width: 100%;
  background: #fafbfc;
}
.dropdown:hover .dropdown-content {
  display: block;
}

.categories {
  padding: 0;

  display: flex;
  list-style: none;
}

.categories a:hover {
  background-color: #c0c0c0;
  padding: 1rem;
}

/* verify phone */
.verify-phone,
.verify-sms {
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 1rem auto;
}
/* shipping */
.shipping {
  display: inline-block;
  vertical-align: middle;
  padding: 1rem;
}

/* start OrderScreen.js */
.order-header h3 {
  font-size: 3rem;
}
div .order_btn__main {
  background-color: #f36127;
  
}

div .order_btn__secondary {
  background-color: black;
}
