.discountBox__outta {
  width: 100%;
  padding: 0 90px 90px 90px;
}

.discountBox__inner {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.discountBox__inner img {
  border: none;
}
.img-thumbnail {
  border: none;
  width: 180px;
  height: 180px;
}
.discountBox__inner p {
    font-size: x-large;
    width: 350px;
    margin: 30px;
}
.discount__btn{
    background-color: #f36127;
    color: white;
    cursor: pointer;
    width: 350px;
    height: 70px;
    
    border-radius: 15px;
    text-transform: uppercase;
    text-align: center;
    box-shadow: -1px 12px 14px 0px rgba(0, 0, 0, 0.7);

}
.discount__btn p{
    font-size: 23px;
    font-weight: bolder;
    margin:20px 0 0 0 ;
    color: white;
}
.discount__btn p:active, .discount__btn p:hover{
    color: black;
}
