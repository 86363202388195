.card_div .card{
    border: 0px white;
}
.card__style{
    width: 220px;
    height: 280px;
    margin-bottom: 80px;
}
.card__style .card-header{
    border-bottom: 0px white;
    background-color: white;
}
.cardTitle_style{
    margin-top: 25px;

}
.cardTitle_style h2 {
   font-weight: bolder;
   text-transform: uppercase;
}
.img_style{
    width: 200px;
    height: 200px;
}
.cardText__style{
   font-size: 20px;
   letter-spacing: 2px;
   font-family: sans-serif;
   margin-bottom: 60px;
}

.borderBottom{ 
    border-bottom: 3px solid #113067;
}
.borderBottomPlus{ 
    border-bottom: 9px solid #fa923f;
}


@media (max-width: 879px){
    .img_style{
        width: 180px;
        height: 180px;
    }
    .card__style{
        width: 200px;
        height: 260px;
    }
    .cardTitle_style h2 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
     }
     .cardText__style{
        font-size: 20px;
        letter-spacing: 0px;
        font-family: sans-serif;
     }
}

@media (max-width: 768px){
    .img_style{
        width: 150px;
        height: 150px;
    }
    .card__style{
        width: 180px;
        height: 230px;
    }
    .cardTitle_style h2 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
     }
     .cardText__style{
        font-size: 20px;
        letter-spacing: 0px;
        font-family: sans-serif;
     }
}
@media (max-width: 740px){
    .img_style{
        width: 140px;
        height: 140px;
    }
    .card__style{
        width: 180px;
        height: 220px;
    }
    .cardTitle_style h2 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
     }
     .cardText__style{
        font-size: 20px;
        letter-spacing: 0px;
        font-family: sans-serif;
     }
}
@media (max-width: 375px){
    .img_style{
        width: 75px;
        height: 75px;
    }
    .card__style{
        width: 100px;
        height: 185px;
        margin-bottom: 1px;
    }
    .cardTitle_style h2 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 10px;
     }
     .cardText__style{
        font-size: 10px;
        letter-spacing: 0px;
        font-family: sans-serif;
     }
}