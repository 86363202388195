/* **Below** 
Page: Category 
js file : HomeScreen.js
start with className: category_product__outta*/

.category_product__outta {
    margin: 80px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .category_product__inner {
    width: inherit;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .cAndp__title {
    font-size: 3.5rem;
  }
  /* 
  filter */
  
  .filter_div_outta {
    margin: 50px 5px;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .filter_search_btn {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 5px 0px;
    padding: 0px;
    width: 300px;
  }
  .filterInput_label {
    font-size: 2.2rem;
    margin: 20px 0;
    padding: 0;
  }
  /* @media (min-width:375px){
    .filter_search_btn {
      margin: 5px 0px;
      padding: 0 5px;
    }
    .filterInput_label {
      font-size: 1.5rem;
    }
  } */
  /* end of search engine 
  
  Below: div for showing products */
  .show_products{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .show_products li {
    list-style-type: none;
    padding: 0;
    flex: 0 1 20rem;
    margin: 1rem;
    height: auto;
    width: 200px;
    border: 0.1rem #c0c0c0 solid;
    border-radius: 10px;
  }
  .product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .product a:hover, .product a:active{
      color: #f36127;
      text-decoration: none;
  }
  .product-name {
    font-size: 2rem;
    font-weight: bold;
  }
  .product-brand {
    font-size: 1.9rem;
    color: #808080;
  }
  .product-price {
    font-size: 2rem;
    font-weight: bold;
  }
  .product img{
    border: none;
  }
  
  .product-rating {
    margin-bottom: 1rem;
  }